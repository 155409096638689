
import { useCookies } from "react-cookie";
import Mbutton from "./menubutton"
import NotificationsIcon from '@mui/icons-material/Notifications';
import {Openai , Huggingface, Docker,Contact , Locations} from './logos'
import { Divider } from "@mui/material";
import HuggingFaceImageGenerator from "./hugginface";
import ShareButton from "./sharebutton";

import Widget from "./banner_widget";
import DevelopmentTimeChart from "./graph";
import Login from "./login";
import HomeAssistantWebSocket from "./hassio";
import HassioWidget from "./hassiowidget";
import { Innovation , Youtube , Website , Pdf , Arxiv} from "./logos";
import SocialMediaMessageSender from "./contactsocial";
import { Button } from "@mui/joy";




const Menu = ()=>{
    const [cookies, setCookie, removeCookie] = useCookies();
    function remove_cookies(){
        removeCookie("name");
        removeCookie("email");
        removeCookie("picture");
        removeCookie("birthday");
        removeCookie("hometown");
        removeCookie("gender");
        removeCookie("location");
        removeCookie("about");
        removeCookie("education");
        removeCookie("work");
        removeCookie("id");
        removeCookie("login")}



    

    const colors={}
    return(
    

        <div className={`flex m-auto    items-end w-screen  h-72 App-header ai-image `} >

       
            

       
    <div className=" backdrop-blur-3xl  flex flex-col  h-full   w-full mb-0  items-end">
        
        
   <div className="flex  menu-tagline  m-auto   w-full  h-36  justify-end px-24 text-slate-400 text-3xl font-thin " >
           <div className="h-full backdrop-blur-3xl  flex  px-8  m-auto items-center  text-5xl kmail  duration-700  w-auto items-end   border-r " >
           km<div className={`hover:rotate-[0deg]  rotate-[180deg] -translate-x-4 hover:-translate-x-12  hover:text-7xl font-black  duration-700`} >Ai</div><div className="-translate-x-12">l</div>
       
            

            </div>
  Tools to elevat your business  creativit 
   </div>
         <div className="flex  h-24   w-full mb-0  items-end  " >
<Mbutton required={false} text={"Home"} href={"/"} colors ={colors} children={
    [ 
        <Widget title="contact us" 
        description=<div className='text-slate-500 text-sm text-justify' >
    <h2 >Mohammed Kmail</h2>
    <p ><strong>Email:</strong> <a href="mailto:kmail@kmail.at" >kmail@kmail.at</a></p>
    <p ><strong>Tel:</strong> <a href="tel:+436641540270" >+43 664 1540270</a></p>
</div> logo={<Contact/>} />,
       

    

    <Widget title="Locations" description= <div className=" flex flex-col m-auto text-sm text-slate-500 text-justify w-1/2 gap-2"><div  > <strong>Vienna, Austria: </strong>Our Vienna office embodies European quality and innovation, specializing in advanced technology, engineering, and architecture. </div> <Divider className="" /><div><strong>Jeddah, Saudi Arabia: </strong>Strategically located in Jeddah, our Saudi Arabia office delivers expert solutions in construction, petrochemicals, and electro installations, tailored to the region.</div> </div>   logo={<Locations/>} />,
     <Widget title="Weather" description={
        <HassioWidget entity_id="weather.forecast_home"  
        attributes={[ ["temperature" , "°C"], ["wind_speed","km/h"] ,["cloud_coverage" ,"%"] , ["pressure" ,"hpa"] , ["humidity" , "%"]]}  
        />
        
    } logo={<Innovation/>}  />,

           
        ]
}  /> 


{/* // ---- AI Services ----- */}
<Mbutton text={"AI Services"} href={" /ai"} colors ={colors}  children={
   [ 
   
    <div className=" h-full  my-auto border-x p-5  ">
        <ol className="flex flex-col text-sm justify-left gap-2 ">
       <Button variant="soft" color="neutral"  className="flex flex-col    h-12 w-96   border-x  text-lg "><a href="/instruction">Image generator</a></Button> 

       <Button variant="soft" color="neutral" className="flex flex-col  h-12 w-96   border-x  text-sm "><a href="/chatbotinstructions" >ChatBot</a></Button>
        

        </ol>
        
        </div>,
    
<div className="flex flex-col text-sm justify-center text-center m-auto  w-48    ">

<div className="text-center "> Retrieval Augmented Generation</div>  

<div className="flex  justify-between text-center m-auto  gap-3 "> 
    <Pdf size={40} />
    <Youtube />      
<Website />
<Arxiv />
</div>
<ul className="text-xs text-slate-500 ">  

    <li> Chat with Pdf documents</li>
    <li> Talk to Youtube videos</li>
    <li> Retrive data from with websites</li>
    <li> Ask with Arxiv Papers</li>
    </ul>

</div>,

    <div className="flex flex-col justify-center text-center m-auto h-full w-96  items-center border-x  text-sm ">
        
<Huggingface  />
<div>Huggingface</div>
        <i>Natural Language Processing (NLP)</i>
        <a>Computer Vision</a>
        <a>Generative AI</a>
        <Divider className="w-1/2 p-2" />
        <a>
            Langchain
        </a>


        </div>,
   ]
}/>


{/* //-- end AI Services ---- menu items */}

{/* <Mbutton text={"BIM"} colors ={colors} /> */}
{/* <Mbutton  text={"Architecture"} href={"architecture"} colors ={colors} /> */}
<Mbutton text={"Containers"} href={"/container"} colors ={colors} children={[<Widget title="Containerization" 
    
    description = <div className='text-slate-500 text-sm text-justify' > 
    <div className='text-lg text-slate-950 text-center'>Time saving % ~40–50% </div>
    Containerization is a lightweight alternative to full machine virtualization that involves encapsulating an application in a container with its own operating environment. Containers share the host system's kernel and are therefore more lightweight than virtual machines. </div>
    
    logo={<Docker/>} />
    ]} />
<Mbutton text={"Blog"} colors ={colors} href={'/blog'}/>




<Mbutton text={cookies['login'] ? "Logout" : 'login'} onClick={()=>{
  
    remove_cookies()
   
}} colors ={colors} href={'login'} />
<div className="flex  h-24 menuitem    w-full  justify-evenly">
<HassioWidget entity_id="weather.forecast_home"  
attributes={[  ["temperature" , "°C"], ["wind_speed","km/h"] ]}  state={false} />
            </div>
     
    
        {cookies['picture'] ? <div className="flex menuitem  h-24  w-full "> <div className="flex justify=center m-auto    "><div> <img className="flex justify-center m-auto rounded-full" src={` ${cookies['picture'] }`}   />
        
         </div> </div></div>   : "" }
    


           
         </div>


  
   
             </ div>
            
        </div>
         
        
    )


}
export default Menu