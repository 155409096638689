
import { useCookies } from "react-cookie";
import { useEffect , useState } from "react";

import { Openai, Teams, Python, Huggingface, Docker, Javascrip ,Architecture , Ailogo, Imagelogo , DownLogo} from "../components/logos";

import SoftwareWidget from "../components/softwarewidget";



import { Typography } from "@mui/joy";
import { Divider } from "@mui/material";



const Downloads =()=>{



          
           
           
       




    return(
        <div className="flex flex-col mx-auto  h-screen    ">
         
      





<div className="grid grid-cols-12 text-center items-center w-full h-48 justify-center bg-slate-50 " >
  <Typography color="success" level="h1" variant="primary" className=" col-span-12 text-center font-thin text-slate-950 " 
 >Software Area
 </Typography>
 <Typography level="body" variant="primary" className="col-span-12 text-center text-sm font-thin text-slate-950"
  >Download the latest software tools and techniques</Typography>

</  div>
<div className="grid grid-cols-12 text-center items-center w-full justify-center"> 


<Divider className="col-span-12  "></Divider>


<div className="col-span-1"></div>
            <SoftwareWidget className="col-span-4 mt-4"  title="Free AI Image Generator" description="This image generator uses Stable Diffusion 3.5 via the Hugging Face API to create high-quality images from text prompts. It runs in a Docker container, making it easy to deploy and use on any machine. Simply get an API key from Hugging Face, run the container, and you're ready to generate unlimited images, all for free and at impressive speed. The setup is fast, efficient, and ideal for designers, developers, and creatives needing stunning visuals in seconds" filename="imagen.sh" link="instruction" />
            <div className="col-span-1"></div>

<SoftwareWidget className="col-span-5 mt-4 " title="Free AI ChatBot" description="This software is a Docker-based chatbot system featuring two backend services and a React frontend.

Backend Services:

Ollama Backend: Powers the chatbot with the Llama 3.2 AI model for generating intelligent responses.
Flask Backend: Acts as a middleware, managing API requests between the React frontend and the Ollama backend.
React Frontend:

Provides a user-friendly, responsive interface for interacting with the chatbot.
Communicates with the Flask backend to process user inputs and display results seamlessly.
The Docker containers ensure ease of deployment, scalability, and consistent operation across different environments. Users can access the chatbot through a browser at http://localhost:3000." filename="chatbot.sh"  link="chatbotinstructions"/>



</div>
           
   





           
        </div>
    )
}
export default Downloads