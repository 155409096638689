import DownloadButton from "./downloadbutton";
import Box from "./box";
import { Docker, Imagelogo } from "./logos";
import { Divider, Modal } from "@mui/material";
import { useCookies } from "react-cookie";
import LoginModal from "./loginmodal";
import { useState } from "react";
const SoftwareWidget = ({title="" , description="" , filename='info.txt' , className='' , link='' }) => {


  const [cookies, setCookie, removeCookie] = useCookies();
  const [modal, setModal] = useState(null);
  return (
   

<div className={`border w-12/12 xs:w-full shadow-xl  mx-auto  hover:rounded-xl  duration-100 grid grid-cols-12 gap-2 lg:p-2 xs:p-1 ${className}`}  >
    
    
 <div className="flex flex-col h-full   gap-5 col-span-6  text-center text-2xl font-thin text-slate-950  ">
          
          <div className="p-8 text-blue-400 font-thin">{title}</div>
         
          <i className="flex m-auto text-sm text-justify p-4 items-center " >{description}</i>
         <a className="text-sm p-4" href={`/${link}`}>Installation instructions</a>
        
        </div>

    <div className="col-span-6 text-center  font-thin text-slate-950  ">
<Box   text="" span={12} child={[
  <Docker size={75}/>  , 

<div className="   flex  flex-col text-center items-center p-2   duration-300" > 

  
{cookies['login'] ? 
 <DownloadButton text="Install" filename={filename} />
 




:<LoginModal   />}


   
</div>, 

           ]
            
            
        } /> 

    </div>
       

         
           </div>

  
  
  );
}

export default SoftwareWidget;